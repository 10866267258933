.logo {
  width: auto;
  height: 70px;
}

.yellow-btn,
.badge {
  color: #fff !important;
}

.bgcolor,
.yellow-btn,
.badge {
  background-color: #234386 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.membership-card-wrapper {
  overflow: auto;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.membership-card {
  width: 250px;
  padding: 24px 16px;
  text-align: center;
  cursor: pointer;
  border: 2px solid;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.membership-card:hover {
  border-color: #234386;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

.membership-card h4 {
  font-weight: 700;
}

.membership-card h3 {
  font-size: 32px;
  font-weight: 700;
  color: #ba2b2b;
}

.upload-box {
  background-color: #f5f8fc;
  box-shadow: '0px 0px 2px rgba(0, 0, 0, 0.12)';
  text-align: center;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border: 1px solid grey;
  cursor: pointer;
}

.upload-box p {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.category-image {
  height: 16px !important
}